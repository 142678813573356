// Import image sources from assets directory
// Dual Console Boat with colored Outboards (FM70/100 ONLY, single set of images per Zach)
/* import DualConsoleBlackImg from '../../assets/images/studio/packages/dual-console/dual_console_black.png';
import DualConsoleWhiteImg from '../../assets/images/studio/packages/dual-console/dual_console_white.png';
import DualConsoleSilverImg from '../../assets/images/studio/packages/dual-console/dual_console_silver.png'; */

// Inflatable Boat with colored Outboards (Using Yacht Tender 40HP Images for now per Zach)
/* import InflatableFM70BlackImg from '../../assets/images/studio/packages/inflatable/inflatable_fm70_100_black.png';
import InflatableFM70WhiteImg from '../../assets/images/studio/packages/inflatable/inflatable_fm70_100_white.png';
import InflatableFM70SilverImg from '../../assets/images/studio/packages/inflatable/inflatable_fm70_100_silver.png'; */

// Outboards Only by HP & Color (Same images for 70 and 100 HP)
/* import FM15BlackImg from '../../assets/images/studio/outboards/FM15_black.png';
import FM15WhiteImg from '../../assets/images/studio/outboards/FM15_white.png';
import FM40BlackImg from '../../assets/images/studio/outboards/FM40_black.png';
import FM40WhiteImg from '../../assets/images/studio/outboards/FM40_white.png';
import FM40SilverImg from '../../assets/images/studio/outboards/FM40_silver.png';
import FM70_100BlackImg from '../../assets/images/studio/outboards/FM_70_100_black.png';
import FM70_100WhiteImg from '../../assets/images/studio/outboards/FM_70_100_white.png';
import FM70_100SilverImg from '../../assets/images/studio/outboards/FM_70_100_silver.png'; */

import modelConfig from './model-config';
import powerConfig from './power-config';
import hullColorConfig from './hull-color-config';
import outboardColorConfig from './outboard-color-config';
import trailerConfig from './trailer-config';
import moreInfoConfig from './more-info-config';
import fastChargingConfig from './fast-charging-config';


// Configuration Object
const studioConfig = {

    metaDesc: 'Pre-order your 100% electric boat package and outboard now.',

    steps: [
        modelConfig,
        powerConfig,
        hullColorConfig, // Outboards only
        outboardColorConfig,
        trailerConfig, // Packages Only
        moreInfoConfig, // Outboards Only
        /* fastChargingConfig */
    ],

    /*
        Preview Specs Object: 
        - Lookup first by Model ID (index = Model ID - 1)
        - Access proper spec field by name and then lookup value by dependent Field ID(s):
            - peakPower and topSpeeds are accessible by Power ID (index = Power ID - 1)
            - range and price are accessible first by Power ID (index = Power ID - 1) 
            and then Battery ID (index = Battery ID - 1)
    */
    previewSpecs: [

        // Dual Console (Index 0, Model ID 1)
        {
            images: [
                // This first one is for the initial preview image before the 100HP option is chosen
                ['/studio/packages/dual-console/dual_console_v3.jpg'],
                [],
                [
                    '/studio/packages/dual-console/dual_console_v3.jpg',
                    '/studio/packages/dual-console/dual_console_v3.jpg',
                    '/studio/packages/dual-console/dual_console_v3.jpg'
                ]
            ],
            loa: ['21\'6"', '21\'6"', '21\'6"'],
            range: [ '25', '25', '25' ],
            topSpeed: [ ['30', '26.07'], ['30', '26.07'], ['30', '26.07'] ],
            price: [ 120000, 120000, 120000 ]
        },

        // Inflatable (Index 1, Model ID 2)
        {
            images: [
                [
                    '/studio/packages/inflatable/inflatable_fm40_silver_v2.png',
                    '/studio/packages/inflatable/inflatable_fm40_silver_v2.png',
                    '/studio/packages/inflatable/inflatable_fm40_silver_v2.png'
                ],
                [
                    '/studio/packages/inflatable/inflatable_fm70_silver_v2.png',
                    '/studio/packages/inflatable/inflatable_fm70_silver_v2.png',
                    '/studio/packages/inflatable/inflatable_fm70_silver_v2.png'
                ],
                [
                    '/studio/packages/inflatable/inflatable_v4.jpg',
                    '/studio/packages/inflatable/inflatable_v4.jpg',
                    '/studio/packages/inflatable/inflatable_v4.jpg'
                ]
            ],
            loa: ['12\'10"', '15\'1"', '21\'10"'],
            range: [ '26', '30', '30' ],
            topSpeed: [ ['32', '28'], ['35', '35'], ['35', '35'] ],
            price: [ 'Inquire', 'Inquire', 110000 ]
        },

        // Center Console (Index 2, Model ID 3)
        {
            images: [
                // This first one is for the initial preview image before the 100HP option is chosen
                ['/studio/packages/center-console/center_console.jpg'],
                [],
                [
                    '/studio/packages/center-console/center_console.jpg',
                    '/studio/packages/center-console/center_console.jpg',
                    '/studio/packages/center-console/center_console.jpg'
                ]
            ],
            loa: ['21\'6"', '21\'6"', '21\'6"'],
            range: [ '25', '25', '25' ],
            topSpeed: [ ['30', '26.07'], ['30', '26.07'], ['30', '26.07'] ],
            price: [ 120000, 120000, 120000 ]
        },

        // Outboard Only (Index 3, Model ID 4)
        {
            images: [
                [
                    '/studio/outboards/FM40_black.png',
                    '/studio/outboards/FM40_white.png',
                    '/studio/outboards/FM40_silver.png'
                ],
                [
                    '/studio/outboards/fm_70_100_neon_black.png',
                    '/studio/outboards/fm_70_100_neon_titanium.png',
                    '/studio/outboards/fm_70_100_neon_silver.png'
                ],
                [
                    '/studio/outboards/fm_70_100_neon_black.png',
                    '/studio/outboards/fm_70_100_neon_titanium.png',
                    '/studio/outboards/fm_70_100_neon_silver.png'
                ]
            ],
            loa: ['12\'10"', '15\'1"', '17\'2"'],
            range: [ '40', '50', '60' ],
            topSpeed: [ ['32', '21.72'], ['38', '30.41'], ['38', '34.76'] ],
            price: [ 'Inquire', 'Inquire', 'Inquire' ]
        }
    ]

};

export default studioConfig;