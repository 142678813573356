import React, { useEffect } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';

import { Outlet } from 'react-router-dom';
import ReactGA from 'react-ga';


export default function MainLayout() {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	
	}, [])
	

  	return (
		<>
			<Header />
	
            <main>

                <Outlet />

            </main>

            <Footer />
		
		</>
  	)
}
