import { useEffect, useRef, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import PageTitle from '../components/PageTitle';
/* import Loader from '../components/studio-components/Loader'; */
import MobileSteps from '../components/studio-components/MobileSteps';
import Preview from '../components/studio-components/Preview';
import Step from '../components/studio-components/Step';
import OrderSuccessModal from '../components/studio-components/OrderSuccessModal';

// Configuration File
import studioConfig from '../config/studio/studio-config';

// Scroll Utility
import scrollUtil from '../lib/scroll-utility';

// Portions of Studio configuration object
const stepsConfig = studioConfig.steps;
const models = stepsConfig[0].choices;
const powers = stepsConfig[1].choices;
const hullColors = stepsConfig[2].choices;
const outboardColors = stepsConfig[3].choices;
const previewSpecs = studioConfig.previewSpecs;

  // URL to AWS API Gateway (dev)
//   const checkoutApiUrl = "https://31gr7eo617.execute-api.us-east-1.amazonaws.com/dev/stripe/checkout";
  
  // URL to AWS API Gateway (main)
  const checkoutApiUrl = "https://t303c3gczc.execute-api.us-east-1.amazonaws.com/main/stripe/checkout";

// For mobile scroll tracking
const useIntersectionObserver = (setActiveId) => {
	const sectionsRef = useRef({});

	useEffect(() => {

		const callback = (sections) => {
			sectionsRef.current = sections.reduce((map, sectionElement) => {
				map[sectionElement.target.id] = sectionElement;
				return map;
			}, sectionsRef.current);

			const visibleSections = [];
			Object.keys(sectionsRef.current).forEach((key) => {
				const sectionElement = sectionsRef.current[key];
				if (sectionElement.isIntersecting) {
					visibleSections.push(sectionElement);
				}
			});

			const getIndexFromId = (id) => {
				// scrollSections??
				sections.findIndex((section) => section.id === id);
			};

			if (visibleSections.length === 1) {
				setActiveId(visibleSections[0].target.id);
			}
			else if (visibleSections.length > 1) {
				const sortedVisibleSections = visibleSections.sort(
                    (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
				);
				// setActiveId(sortedVisibleSections[0].target.id);
                setActiveId(sortedVisibleSections[1].target.id);
			}
		};
	
		// -65px: Cover the sticky header height plus one pixel so nothing behind it counts as in view
		//  -40%: Cover the bottom 40% of the view so a section isn't considered visible until it's close to the middle
		const observer = new IntersectionObserver(callback, {
			rootMargin: '-65px 0px -50% 0px',
			/* threshold: 0.5 // half of the item height */
		});

		const scrollSections = Array.from(document.querySelectorAll('#preview, #config'));

		scrollSections.forEach((element) => observer.observe(element));

		return () => observer.disconnect();

	}, [setActiveId]);
	
};


export default function Studio() {

    // Retrieve state passed from Link element
    const location = useLocation();

    // Loaded state
    /* const [loaded, setLoaded] = useState(false);
    const [hideLoader, setHideLoader] = useState(false); */

    // Ref for Configure region
    const previewRef = useRef(null);
    const configureRef = useRef(null);

    // For mobile scroll tracking between Top Preview and Bottom Config sections
    const [activeMobileId, setActiveMobileId] = useState();
    useIntersectionObserver(setActiveMobileId);

    // Encapsulated Steps array
    const [steps, setSteps] = useState(stepsConfig.map((step, i) => {
        return {
            ...step,
           /*  choices: i === 1 ? [...step.choices].reverse() : step.choices, */
            active: i === 0,
            validated: false
        }
    }));

    // Store steps in state (steps can change in length & order based on model & power selections)
    // const [steps, setTestSteps] = useState([...stepsConfig]);

    // Stripe-related variables and state management
    const href = window.location.href;
    const [sessionUrl, setSessionUrl] = useState(null);
    const [retUrl] = useState(href);
    const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);

    // Encapsulated Preview image/specs object
    const [preview, setPreview] = useState({
        image: null,
        loa: 'XX\'X"',
        model: null,
        range: 'XX',
        topSpeed: ['XX', 'XX'],
        price: 'XX,XXX'
    });

    // Encapsulated User Selections Object
    const [selections, setSelections] = useState({
        model: '',
        power: '',
        outboardColor: '',
        trailer: null,
        moreInfo: ''
    });

    // const [quantity, setQuantity] = useState(1);

    // Tracking Active Step
    const [currStep, setCurrStep] = useState(1);
    const [currStepDelayed, setCurrStepDelayed] = useState(1);

    // On smaller devices, scroll to hidden configure section
    const handleScrollClick = () => {
        scrollUtil.scrollToSection(activeMobileId === 'preview' ? configureRef : previewRef);
    }

    // Generic click handler for user selections
    const handleChoiceClick = (field, fieldsToClear, choiceObj) => {
        setSelections(prevState => {
            let updates = {
                ...prevState,
                [field]: choiceObj.id
            };

            if (fieldsToClear) {
                fieldsToClear.forEach((field) => updates[field] = '');
            }

            return updates;
        });
    };

    // Use selected values to build a product string for Stripe
    const buildProductString = () => {
        let productString = '', currSelectionId, currSelection;

        stepsConfig.forEach((step, i) => {
            currSelectionId = selections[step.fieldName];

            if (i > 0) {
                productString += '_';
            }

            // If a selection was made for the current step...
            if (currSelectionId) {
                currSelection = step.fieldName === 'power' ? step.choices[Math.abs(currSelectionId - 3)] : step.choices[currSelectionId - 1];
    
                // If this is the first step, handle the Model edge case
                if (i === 0) {
                    switch (currSelectionId) {
                        case 1:
                            productString += currSelection.name.replace(' ', '');
                            break;
                        case 2:
                            const nextStep = stepsConfig[i+1],
                                nextSelectionId = selections[nextStep.fieldName];
                            /* productString += nextStep.choices[nextSelectionId - 1].hullModel; */
                            productString += nextStep.choices[Math.abs(nextSelectionId - 3)].hullModel;
                            break;
                        case 3:
                            productString += currSelection.name.replace(' ', '');
                            break;
                        default:
                            productString += 'NA';
                    }
                }
                
                // Else follow a standard flow
                else {
                    productString += currSelection.name === 'Other Boat Package' ? 'Other' : currSelection.name;
                    productString += (currSelection.unit || '');
                }
            }
            
            // Else, append 'NA' to the Product String
            else {
                productString += 'NA';
            }
        });

        console.log(productString);
        return productString;
    };

    const handleOrderClick = (e) => {
        e.preventDefault();
        const productStr = buildProductString(); 
        initializeStripeSession(productStr);
    };

    // Hide order success modal
    const closeOrderSuccessModal = () => {
        setShowOrderConfirmation(false);
        // window.location.href = retUrl.split('?success')[0];
        window.location ='/';
    };

    const initializeStripeSession = async (productStr) => {
        try {

            setSessionUrl(null);

            const response = await fetch(checkoutApiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    product_name: productStr,
                    success_url: `${retUrl}`,
                    cancel_url: `${retUrl}`,
                }),
            });

            console.log('fetch response = ', response);

            const data = await response.json();
            if (data != null && data['location'] != null) {
                setSessionUrl(data['location']);
            }

            response.ok ?
                console.log('Place order succeeded.')
                : console.error('Error submitting form:', response.status);
        }
        
        catch (error) {
            // Handle network or other errors
            console.error('Error submitting form:', error);
        }
    };

    const updatePreview = (selections) => {
        const { model, power, outboardColor, trailer } = selections;
        const specsByModel = previewSpecs[model - 1];

        const modelText = models[model - 1].altTextName,
              colorText = outboardColors[outboardColor ? outboardColor - 1 : 0].name,
              powerVal = powers[power ? power - 1 : 2].name;

        const basePrice = specsByModel.price[power ? power - 1 : 2];

        const totalPrice = basePrice === 'Inquire' ?
                        basePrice 
                        : (
                            basePrice
                            + (trailer === 1 ? models[model - 1].trailerPrice(power) : 0)
                        );

        setPreview({
            altText: `Rendering of a Flux Marine ${modelText} electric boat with a ${colorText} FM${powerVal} ${powerVal}HP electric outboard motor.`,
            image: specsByModel.images[power ? power - 1 : 2][outboardColor ? outboardColor - 1 : 0],
            loa: specsByModel.loa[power ? power - 1 : 2],
            model: model,
            range: specsByModel.range[power ? power - 1 : 2],
            topSpeed: specsByModel.topSpeed[power ? power - 1 : 2],
            price: totalPrice
        });
    };

    // Return true if a selection has been made for a particular step/field
    const validateCurrStep = (field) => {
        return  selections[field] || false;
    };

    // Decrement Step
    const prevStep = () => {
        setCurrStep(currStep - 1);
    }

    // Increment Step
    const nextStep = () => {
        setCurrStep(currStep + 1);
    };

    useEffect(() => {
        if (selections.model) {
            updatePreview(selections);

            setSteps(() => {
                let updated = [];

                // Adjust steps based on selected model
                if (selections.model < 4) {
                    updated = updated.concat(stepsConfig.slice(0,5));
                } else {
                    updated = updated.concat(stepsConfig.slice(0,2), stepsConfig.slice(3,4), stepsConfig.slice(5,6));
                }

                // Adjust steps based on selected power, if applicable
                /* if (selections.power && selections.power > 2) {
                    updated = updated.concat(stepsConfig.slice(6));
                } */

                updated = updated.map((step, i) => {
                    return {
                        ...step,
                        validated: validateCurrStep(i+1)
                    }
                });

                return updated;
            });
        }
    }, [selections]);

    // When the step changes, use a timeout and separate state value to create a clean fade effect
    useEffect(() => {
        setTimeout(() => {
            setCurrStepDelayed(currStep);
        }, 150);
    }, [currStep]);

    // When the router location object is retrieved, preselect a model or default to Rigid Inflatables
    useEffect(() => {
        setSelections(prevState => {
            const preselections = location.state;
            return {
                ...prevState,
                model: preselections && preselections.modelId ? preselections.modelId : 2
            }
        });

        // We aren't supporting this yet for specific outboard model 
        /* if (location.state.powerId) {
            handlePowerClick(powerOptions.filter((opt) => opt.id === location.state.powerId)[0]);
        } */
    }, [location]);

    // Effect Hook to handle redirect back from Stripe Checkout page
    useEffect(() => {
        // console.log('href = ', href);
        const query = new URLSearchParams(window.location.search);
        // console.log('query = ', query);

        if (query.get("success")) {
            setShowOrderConfirmation(true);
            return;
        }
    }, []);

    // On component mount, execute the loading flow once
    /* useEffect(() => {
        setTimeout(() => setLoaded(true), 4000);
        setTimeout (() => setHideLoader(true), 4750);
    }, []); */

    // Returns true if the user has made all required selections & false if not
    const isComplete = () => {
        let complete = true;
        steps.forEach((step) => {
            if (!selections[step.fieldName]) {
                complete = false;
            }
        });
        return complete;
    };

    // If there's an existing checkout session, then redirect to Stripe checkout
    if (sessionUrl != null) {
        window.location.href = sessionUrl;
    }


  	return (

		<div className='fm-page' data-page='studio'>

            <PageTitle
                title='Studio'
                desc={studioConfig.metaDesc}
            />
            
            <div className={`fm-studio fm-studio--loaded fm-full-height d-flex flex-column flex-lg-row`}> {/* ${loaded ? 'fm-studio--loaded' : ''} */}

                {/* { !hideLoader && (
                    <Loader />
                )} */}

                <div className='fm-studio__preview d-flex flex-column' id='preview' ref={previewRef}>

                    <MobileSteps
                        activeStep={currStep}
                        changeStep={setCurrStep}
                        steps={steps}
                    />

                    <Preview previewObj={preview} />
                </div>

                <div className='fm-studio__config d-lg-flex flex-lg-column justify-content-lg-between' id='config' ref={configureRef}>

                    <div className='fm-studio__section'>

                        <div className='fm-studio__steps d-flex justify-content-between'>

                            {currStep > 1 && (
                                <button
                                    className='fm-studio__nav-btn'
                                    onClick={prevStep}
                                >
                                    <FontAwesomeIcon className='' icon={`fa-solid fa-chevron-left`} />
                                    <span className='text-capitalize'>
                                        {currStep - 1}. {steps[currStep - 2].name}
                                    </span>
                                </button>
                            )}

                            {currStep <= steps.length && (
                                <button
                                    className={`fm-studio__nav-btn ms-auto ${!validateCurrStep(steps[currStep - 1].fieldName) ? 'disabled' : ''}`}
                                    onClick={nextStep}
                                    tabIndex={!validateCurrStep(steps[currStep - 1].fieldName) ? -1 : ''}
                                >
                                    <span className='text-capitalize'>
                                        {currStep + 1}. {steps[currStep] ? steps[currStep].name : 'Order Summary'}
                                    </span>
                                    <FontAwesomeIcon className='' icon={`fa-solid fa-chevron-right`} />
                                </button>
                            )}

                        </div>

                        <div className='fm-studio__sandbox d-flex'>
                            <div className='w-100'>

                                {/* Step 1: Model */}
                                {currStep === 1 && (
                                    <Step
                                        active={currStepDelayed === 1}
                                        clickHandler={handleChoiceClick}
                                        currSelection={selections[steps[currStep - 1].fieldName]}
                                        handleNextClick={nextStep}
                                        nextStep={steps[currStep] ? steps[currStep].name : 'Order Summary'}
                                        stepNo={currStep}
                                        {...steps[currStep - 1]}
                                    />
                                )}
                                {/* END Step 1: Model */}


                                {/* Step 2: Power */}
                                {currStep === 2 && (
                                    <Step
                                        active={currStepDelayed === 2}
                                        clickHandler={handleChoiceClick}
                                        currSelection={selections[steps[currStep - 1].fieldName]}
                                        handleNextClick={nextStep}
                                        nextStep={steps[currStep] ? steps[currStep].name : 'Order Summary'}
                                        dependentSelection={
                                            currStep > 1 && stepsConfig[currStep - 1].choiceDependency 
                                                ? selections[stepsConfig[currStep - 1].choiceDependency]
                                                : ''
                                        }
                                        stepNo={currStep}
                                        {...steps[currStep - 1]}
                                    />
                                )}
                                {/* END Step 2: Power */}

                                {/* Step 3: Hull Color (Packages Only) */}
                                { (currStep === 3 && selections.model < 4) && (
                                    <Step
                                        active={currStepDelayed === 3}
                                        clickHandler={handleChoiceClick}
                                        currSelection={selections[steps[currStep - 1].fieldName]}
                                        handleNextClick={nextStep}
                                        nextStep={steps[currStep] ? steps[currStep].name : 'Order Summary'}
                                        dependentSelection={
                                            currStep > 1 && stepsConfig[currStep - 1].choiceDependency 
                                                ? selections[stepsConfig[currStep - 1].choiceDependency]
                                                : ''
                                        }
                                        stepNo={currStep}
                                        {...steps[currStep - 1]}
                                    />
                                )}
                                {/* END Step 3: Hull Color */}


                                {/* Step 3 (Outboards) / 4 (Packages): Outboard Color */}
                                { ((currStep === 3 && selections.model === 4) || (currStep === 4 && selections.model < 4)) && (
                                    <Step
                                        active={( (currStepDelayed === 3 && selections.model === 4) || (currStepDelayed === 4 && selections.model < 4) )}
                                        clickHandler={handleChoiceClick}
                                        currSelection={selections[steps[currStep - 1].fieldName]}
                                        handleNextClick={nextStep}
                                        nextStep={steps[currStep] ? steps[currStep].name : 'Order Summary'}
                                        dependentSelection={
                                            currStep > 1 && stepsConfig[currStep - 1].choiceDependency 
                                                ? selections[stepsConfig[currStep - 1].choiceDependency]
                                                : ''
                                        }
                                        stepNo={currStep}
                                        {...steps[currStep - 1]}
                                    />
                                )}
                                {/* END Step 3/4: Outboard Color */}


                                {/* Step 4: More Info (Outboard Only) */}
                                {currStep === 4 && selections.model === 4 && (
                                    <Step
                                       active={currStepDelayed === 4}
                                        clickHandler={handleChoiceClick}
                                        currSelection={selections[steps[currStep - 1].fieldName]}
                                        handleNextClick={nextStep}
                                        nextStep={steps[currStep] ? steps[currStep].name : 'Order Summary'}
                                        dependentSelection={
                                            currStep > 1 && stepsConfig[currStep - 1].choiceDependency 
                                                ? selections[stepsConfig[currStep - 1].choiceDependency]
                                                : ''
                                        }
                                        stepNo={currStep}
                                        {...steps[currStep - 1]}
                                    />
                                )}
                                {/* END Step 4: More Info (Outboard Only) */}


                                {/* Step 5: Trailer (Packages Only) */}
                                {currStep === 5 && selections.model < 4 && (
                                    <Step
                                        active={currStepDelayed === 5}
                                        clickHandler={handleChoiceClick}
                                        currSelection={selections[steps[currStep - 1].fieldName]}
                                        handleNextClick={nextStep}
                                        nextStep={steps[currStep] ? steps[currStep].name : 'Order Summary'}
                                        dependentSelection={
                                            currStep > 1 && stepsConfig[currStep - 1].choiceDependency 
                                                ? selections[stepsConfig[currStep - 1].choiceDependency]
                                                : ''
                                        }
                                        stepNo={currStep}
                                        {...steps[currStep - 1]}
                                    />
                                )}
                                {/* END Step 5: Trailer (Packages Only) */}

                                
                                {/* Step 5 (Outboards) / 6 (Packages): DC Fast Charging (70HP & 100HP Only) */}
                                {/* { ( ((currStep === 5 && selections.model === 4) || (currStep === 6 && selections.model < 4)) && selections.power > 2 ) && (

                                    <Step
                                        active={ (currStepDelayed === 5 && selections.model === 4 ) || (currStepDelayed === 6 && selections.model < 4) }
                                        clickHandler={handleChoiceClick}
                                        currSelection={selections[steps[currStep - 1].fieldName]}
                                        handleNextClick={nextStep}
                                        nextStep={steps[currStep] ? steps[currStep].name : 'Order Summary'}
                                        dependentSelection={
                                            currStep > 1 && stepsConfig[currStep - 1].choiceDependency 
                                                ? selections[stepsConfig[currStep - 1].choiceDependency]
                                                : ''
                                        }
                                        stepNo={currStep}
                                        {...steps[currStep - 1]}
                                    />

                                )} */}
                                {/* END Step 5/6: DC Fast Charging (70HP & 100HP Only) */}


                                {/* Step 5/6: Order Summary */}
                                {currStep > steps.length &&  (

                                    <div className={`fm-studio__step ${currStepDelayed > steps.length ? 'fm-studio__step--active' : ''}`}>

                                        <h2 className='fm-studio__section-title text-center'>{currStep}. Order Summary</h2>

                                        <div className='fm-studio__summary'>

                                            <div className='d-flex flex-row flex-sm-column justify-content-center justify-content-sm-start align-items-center align-items-lg-start'>
                                                <div className='fm-studio__summary-label'>Model:</div>
                                                <p className='fm-studio__summary-val'>
                                                    {selections.model ? steps[0].choices[selections.model - 1].name : 'None'}
                                                    
                                                    {selections.model === 2 && (
                                                       /*  <> - {steps[1].choices[selections.power - 1].hullModel}</> */
                                                       <> - {steps[1].choices[Math.abs(selections.power - 3)].hullModel}</>
                                                    )}
                                                </p>
                                            </div>

                                            {steps.slice(1).map((step, i) => {

                                                const stepName = step.name,
                                                      currSelectionId = selections[step.fieldName],
                                                      currSelection = step.fieldName === 'power' ? step.choices[Math.abs(currSelectionId - 3)] : step.choices[currSelectionId - 1];     

                                                return (
                                                    <div
                                                        className='d-flex flex-row flex-sm-column justify-content-center justify-content-sm-start align-items-center align-items-lg-start'
                                                        key={stepName}
                                                    >
                                                        <div className='fm-studio__summary-label'>{stepName}:</div>
                                                            <p className='fm-studio__summary-val'>
                                                                {
                                                                    currSelectionId ? 
                                                                        `${currSelection.name}${currSelection.unit ? ` ${currSelection.unit}` : ''}`
                                                                        : 'None'
                                                                }
                                                            </p>
                                                    </div>
                                                );

                                            })}
                                            
                                        </div>

                                        {/* <div className='fm-studio__quantity fm-input-wrapper fm-input-wrapper--num'>
                                            <label htmlFor='quantity'>
                                                Quantity:
                                            </label>
                                            <input
                                                className='fm-form__input'
                                                id='quantity'
                                                min='1'
                                                name='quantity'
                                                onChange={(e) => setQuantity(e.target.value < 1 ? 1 : e.target.value)}
                                                type='number'
                                                value={quantity}
                                            />
                                        </div> */}

                                        {selections.model !== 4 && preview.price !== 'Inquire' && (
                                            <div className='fm-studio__total fm-fw-semibold text-center text-lg-start'>
                                                Total: ${preview.price.toLocaleString()}
                                            </div>
                                        )}

                                        {preview.price !== 'Inquire' && (
                                            <div className='fm-studio__due-now fm-fw-semibold text-center text-lg-start'>
                                                Due Today: ${selections.model === 4 ? 100 : 500}
                                            </div>
                                        )}

                                        {(selections.model === 4 || preview.price === 'Inquire') && (
                                            <Link
                                                className='fm-studio__inquire-btn fm-btn fm-btn--accent'
                                                state={{
                                                    scrollTo: 1,
                                                    inquiry: 'Speak with the Sales Team',
                                                    msg: `I am interested in the ${selections.model ? steps[0].choices[selections.model - 1].name : ''}${selections.model === 2 ? ` ${steps[1].choices[Math.abs(selections.power - 3)].hullModel}` : '' } and would like to learn more.`
                                                }}
                                                to='/about'
                                            >
                                                <span>Inquire</span>
                                            </Link>
                                        )}

                                    </div>

                                )}
                                {/* END Step 5/6: Order Summary */}

                            </div>
                        </div>

                    </div>


                    {/* Summary / Place Order Section */}
                    <div className='fm-studio__section text-center'>

                        {preview.price !== 'Inquire' && (
                            <a
                                className={`fm-studio__order-btn fm-btn ${!isComplete()? 'disabled' : ''}`}
                                href='#'
                                onClick={handleOrderClick}
                            >
                                Place Your Order
                            </a>
                        )}

                        {currStep <= steps.length && (
                            
                            <div className='fm-studio__selections d-flex flex-column align-items-start'>

                                {steps.map((step, index) => {

                                    const {choices, fieldName, name, unit} = step,
                                        currSelection = selections[fieldName] ? 
                                            (fieldName === 'power' ?  choices[Math.abs(selections[fieldName] - 3)] : choices[selections[fieldName] - 1])
                                            : null;
                                    
                                    return (
                                        <button
                                            className={`fm-studio__jump-to-section ${currStep === index + 1 || !validateCurrStep(fieldName) ? 'disabled' : ''}`}
                                            key={name}
                                            onClick={() => setCurrStep(index+1)}
                                        >
                                            <FontAwesomeIcon icon='fa-solid fa-pencil' />
                                            <span className='fm-fw-semibold'>{name}:&nbsp;</span>
                                            <span>
                                                {
                                                    currSelection ? 
                                                        `${currSelection.name}${currSelection.unit ? ' ' + currSelection.unit : ''}`
                                                        : 'Not yet selected'
                                                }
                                            </span>
                                        </button>
                                    );

                                })}

                            </div>
                        )}

                        {currStep > steps.length && (

                            <button
                                className={`fm-studio__summary-back-btn fm-btn fm-btn--secondary`}
                                onClick={() => setCurrStep(1)}
                            >
                                Modify Your Order
                            </button>

                        )}

                        { (currStep > steps.length && preview.price !== 'Inquire') && (
                            <Link
                                className='fm-studio__contact-btn fm-btn fm-btn--secondary'
                                state={{
                                    scrollTo: 1,
                                    inquiry: 'Speak with the Sales Team',
                                    msg: `I am interested in the ${selections.model ? steps[0].choices[selections.model - 1].name : ''}${selections.model === 2 ? ` ${steps[1].choices[Math.abs(selections.power - 3)].hullModel}` : '' } and would like to learn more.`
                                }}
                                to='/about'
                            >
                                <span>Contact Sales</span>
                            </Link>
                        )}

                    </div>


                </div>

                <div className='fm-studio__mobile-controls d-flex justify-content-center align-items-center d-lg-none'>
                    <button
                        className='fm-btn fm-btn--secondary'
                        onClick={handleScrollClick}
                    >
                        <span className='text-capitalize'>{activeMobileId === 'preview' ? 'Configure' : 'Preview'}</span>
                        <FontAwesomeIcon icon={`fa-solid fa-chevron-${activeMobileId === 'preview' ? 'down' : 'up'}`} />
                    </button>

                    {preview.price !== 'Inquire' && (
                        <a
                            className={`fm-studio__order-btn fm-btn ${!isComplete() ? 'disabled' : ''}`}
                            href='#'
                            onClick={handleOrderClick}
                        >
                            Place Order
                        </a>
                    )}
                    
                </div>

            </div>

            <OrderSuccessModal
                closeFunc={closeOrderSuccessModal}
                show={showOrderConfirmation}
            />

        </div>

  	)

}
