const boatOptionsConfig = {

    title: 'Powertrain and Boat Combinations',

    combosPanel: {

        combos: [
            {
                altText: 'Rendering of a Flux Marine inflatable electric Boat with a black FM40 40HP electric outboard motor seen from above.',

                image: '/packages/inflatable/SP390.png',

                boat: 'SP390',

                outboard: 'FM40',

                subhead: 'Available in 2025',

                specs: [
                    {
                        label: 'Acceleration Power',
                        value: '70 hp / 52 kW'
                    },
                    {
                        label: 'Cruising Speed',
                        value: '20 mph / 17 knots'
                    },
                    {
                        label: 'Top Speed',
                        value: '32 mph / 28 knots'
                    },
                    {
                        label: 'Length',
                        value: '12\'10\" / 3.90m'
                    },
                    {
                        label: 'Beam',
                        value: '6\'4\" / 1.92m'
                    },
                    {
                        label: 'Passengers',
                        value: '7'
                    },
                    {
                        label: 'Charge Time From 20%-80%',
                        value: (<>
                            <ul>
                                <li>AC (220V): 2-3 hrs</li>
                            </ul>
                        </>)
                    },
                    {
                        label: 'Est. Cruising Range',
                        value: '26 mi / 41 km'
                    },
                    {
                        label: 'Weight',
                        value: '900 lbs / 408 kg'
                    },
                    {
                        label: 'MSRP',
                        value: '$35,000.00'
                    }
                ],

                cta: {
                    customClass: 'fm-btn--reserve',
                    label: 'Reserve',
                    stateObj: {
                        modelId: 2
                    },
                    theme: 'secondary-accent'
                }

            },
            {
                altText: 'Rendering of a Flux Marine inflatable electric Boat with a black FM40 40HP electric outboard motor seen from above.',
                
                image: '/packages/inflatable/SP420.png',
                
                boat: 'SP460',

                outboard: 'FM70',

                specs: [
                    {
                        label: 'Acceleration Power',
                        value: '100 hp / 75 kW'
                    },
                    {
                        label: 'Cruising Speed',
                        value: '25 mph / 22 knots'
                    },
                    {
                        label: 'Top Speed',
                        value: '35 mph / 30 knots'
                    },
                    {
                        label: 'Length',
                        value: '15\'1\" / 4.6m'
                    },
                    {
                        label: 'Beam',
                        value: '6\'10\" / 2.09m'
                    },
                    {
                        label: 'Passengers',
                        value: '8'
                    },
                    {
                        label: 'Charge Time From 20%-80%',
                        value: (<>
                            <ul>
                                <li>AC (220V): 4.5-6.5 hrs</li>
                                <li>DC Fast: 45 mins-1 hr</li>
                            </ul>
                        </>)
                    },
                    {
                        label: 'Est. Cruising Range',
                        value: '30 mi / 48 km'
                    },
                    {
                        label: 'Weight',
                        value: '1,500 lbs / 681 kg'
                    },
                    {
                        label: 'MSRP',
                        value: '$62,000'
                    }
                ],
                
                cta: {
                    label: 'Configure',
                    stateObj: {
                        modelId: 2
                    },
                    theme: 'accent'
                }
            },
            {
                altText: 'Rendering of a Flux Marine inflatable electric Boat with a black FM100 10HP electric outboard motor.',
                
                image: '/packages/inflatable/SP660_v3.png',

                boat: 'SP660',

                outboard: 'FM100',

                specs: [
                    {
                        label: 'Acceleration Power',
                        value: '150 hp / 112 kW'
                    },
                    {
                        label: 'Cruising Speed',
                        value: '20 mph / 17 knots'
                    },
                    {
                        label: 'Top Speed',
                        value: '35 mph / 30 knots'
                    },
                    {
                        label: 'Length',
                        value: '21\'10\" / 6.65m'
                    },
                    {
                        label: 'Beam',
                        value: '8\'5\" / 2.56m'
                    },
                    {
                        label: 'Est. Cruising Range',
                        value: '30 mi / 48 km'
                    },
                    {
                        label: 'Weight',
                        value: '3,400 lbs / 1,543 kg'
                    },
                    {
                        label: 'MSRP',
                        value: '$110,000.00'
                    },
                    {
                        label: 'Charge Time From 20%-80%',
                        value: (<>
                            <ul>
                                <li>AC (220V): 7.5 hrs</li>
                                <li>DC Fast: &lt;45 min</li>
                            </ul>
                        </>)
                    }
                ],

                cta: {
                    label: 'Configure',
                    stateObj: {
                        modelId: 2
                    },
                    theme: 'accent'
                }

            }
        ],

        disclaimer: 'Range extends an additional 10 miles at harbor speeds. Top and Cruising Speeds dependent on passenger count, sea state, and trim.'

    },

    specsPanel: {

        label: 'Charge from 20% to 80% in as little as...',

        specs: [
            {
                label: '110v, 10amp',
                unit: '',
                value: 'Overnight'
            },
            {
                label: '220v, 30amp',
                unit: 'hrs',
                value: '2'
            },
            {
                label: 'Level 3 Fast Charge',
                unit: 'mins',
                value: '45'
            }
        ]

    },

    imagePanel: {

        altText: 'An electric Highield Rigid Inflatable Boat turns at speed kicking up spray at sunset.',

        image: '/packages/inflatable/RIB-Battery.jpg'

    },

    /*platformsPanel: {
        
        platforms: [
            {
                name: 'Standard Range',
                desc: 'Standard battery platform meets the needs for most use cases. The ride is quiet, smooth, and exhilarating.',
                startingPrice: '100,000', //$
                specs: []
            },
            {
                name: 'Extended Range',
                desc: 'Extended battery platform delivers immense power and acceleration with longer range.',
                startingPrice: '130,000', //$
                specs: []
            }
        ]
    }*/

};

export default boatOptionsConfig;